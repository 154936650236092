import React from 'react';
import {Link} from 'react-router-dom';
import {Stack} from "react-bootstrap";

function Deula() {
  return (
    <Stack gap={1}>
      <p className='fw-bold'>이 약관은 2023년 09월 20일 업데이트되었습니다.</p>
      <p className='fw-bold'>Ion 서비스를 사용하기 전 본 약관을 주의깊게 읽어주세요. IonID를 생성함에 따라 본 약관에 동의하는 것으로 간주됩니다.</p>
      <hr/>
      <h3>Ion의 이용</h3>
      <ol>
        <li>Ion 서비스의 이용은 이용하고자 하는 자가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 Ion이 이를 승낙한 후 최종적으로 IonID가 처음 활성화되며
          시작됩니다.
        </li>
        <li>
          <p>Ion은 가입 신청자의 신청을 승낙함을 원칙으로 합니다. 다만 다음 경우에는 승낙을 하지 않고 유보, 거절하거나 IonID를 활성화하지 않을 수 있습니다.</p>
          <ul>
            <li>가입 신청자가 이전에 이용 자격을 상실한 적이 있는 경우</li>
            <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>허위의 정보를 기재하거나 Ion이 요구한 정보를 입력하지 않은 경우</li>
            <li>가입 신청자의 귀책사유로 인하여 승낙이 불가능한 경우</li>
            <li>가입 신청자가 Ion 서비스의 이용 자격이 없는 경우</li>
          </ul>
        </li>
        <li>
          <p>Ion은 본 약관에 의거하여 활성화가 거절된 IonID를 사전 통보 없이 삭제할 수 있습니다.</p>
        </li>
        <li>
          <p>Ion의 이용 자격은 다음 각 조건을 말합니다.</p>
          <ul>
            <li>인천과학고등학교에 재학중인 학생일 것</li>
            <li>인천과학고등학교에 소속된 교사일 것</li>
            <li>이전에 Ion 서비스의 관리권한을 획득한 이용자일 것</li>
          </ul>
        </li>
        <li>Ion은 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우 승낙을 유보할 수 있습니다.</li>
      </ol>
      <h3>Ion의 의무</h3>
      <ol>
        <li>Ion은 관련 법령이나 이 약관이 금지하는 행위를 하지 않으며 계속적으로 안정적인 서비스 제공을 위해 최선을 다하여 노력합니다.</li>
        <li>Ion은 안전한 서비스 이용을 위해 필요한 보안시스템과 암호화를 하고, 개인정보취급방침을 제시합니다.</li>
        <li>Ion은 영리적 목적으로 서비스를 운영하지 않습니다.</li>
      </ol>
      <h3>이용자의 의무</h3>
      <ol>
        <li>
          <p>이용자는 다음과 같은 행위를 해서는 안 됩니다.</p>
          <ul>
            <li>신청 또는 변경시 허위내용의 등록</li>
            <li>타인의 정보도용</li>
            <li>Ion이 개시한 정보의 변경</li>
            <li>Ion 또는 제 3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
            <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 부적절한 정보를 Ion에 공개 또는 개시하는 행위</li>
            <li>영리를 목적으로 서비스를 사용하는 행위</li>
          </ul>
        </li>
        <li>
          <p>이용자는 관계법령, 이 약관의 규정을 준수해야 하며, Ion의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
        </li>
      </ol>
      <h3>서비스의 제공</h3>
      <ol>
        <li>
          <p>Ion은 이용자에게 아래와 같은 서비스를 제공합니다.</p>
          <ul>
            <li>면학 불참 신청 서비스</li>
            <li>급식 정보 제공 서비스</li>
            <li>한강, 인천 앞바다 수온 정보 제공 서비스</li>
          </ul>
        </li>
        <li>서비스의 제공은 업무상, 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 운영을 원칙으로 합니다. 다만 Ion의 책임이 없는 기술적 문제가 발생하거나 정기점검을 하는 경우는
          예외로 합니다.
        </li>
      </ol>
      <h3>서비스 제공의 변경 및 중지</h3>
      <ol>
        <li>
          <p>Ion은 긴급한 시스템 점검, 증설, 교체, 설비의 장애, 서비스 이용의 폭주, 정전, 국가비상사태, 천재지변 등 부득이한 사유가 발생한 경우 사전 예고 없이 일시적으로
            서비스를 중지할 수 있습니다.</p>
        </li>
        <li>
          <p>Ion은 이용 자격을 상실한 이용자의 IonID에 대한 서비스 제공을 중단할 수 있습니다.</p>
        </li>
      </ol>
      <h3>개인정보취급방침</h3>
      <p>Ion은 이용자의 개인정보를 소중하게 여기며 이를 보호하기 위해 최선을 다합니다. 이 단에서는 Ion이 어떤 개인정보를 어떤 이유로 어떻게 수집하고 어떻게 보관, 관리하는지
        설명합니다.</p>
      <ol>
        <li>
          <p className='fw-bold'>수집하는 개인정보와 수집방법</p>
          <ol>
            <li>
              <p className='fw-bold'>개인 정보 수집 항목</p>
              <ul>
                <li>학번</li>
                <li>이름</li>
              </ul>
            </li>
            <li>
              <p className='fw-bold'>개인정보 수집 방법</p>
              <p>홈페이지를 통한 회원가입</p>
            </li>
          </ol>
        </li>
        <li>
          <p className='fw-bold'>개인정보의 수집 및 이용목적</p>
          <p>서비스 이용을 위한 개인 식별, 부정 이용 방지와 비인가 사용 방지</p>
        </li>
        <li>
          <p className='fw-bold'>개인정보의 보유 및 이용기간</p>
          <p>이 사이트는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 이용자의 개인정보는 개인정보의 수집 및 이용목적이 달성되면
            지체 없이 파기됩니다.</p>
          <p className='fw-bold'> 파기 방법</p>
          <p> 전자적 파일형태로 저장된 개인정보는 기록을 복원할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
        </li>
        <li>
          <p className='fw-bold'>개인정보 제공 및 공유</p>
          <p>Ion은 개인정보 제공 이전에 개인정보 제공자, 개인정보 제공 목적, 제공하는 개인정보의 항목을 별도로 알리고 동의절차를 거칩니다.</p>
          <ol>
            <li>Ion은 면학 불참 신청 서비스를 위해 교사 권한이 있는 사용자에게 학번과 이름을 공유할 수 있습니다.</li>
            <li>관리자는 Ion 서비스 운영을 위해 사용자의 학번과 이름을 열람할 수 있습니다.</li>
          </ol>
        </li>
        <li>
          <p className='fw-bold'>개인정보 자동수집 장치의 설치, 운영 및 거부에 관한 사항</p>
          <ol>
            <li>
              <p className='fw-bold'>쿠키</p>
              <p>Ion은 이용자의 인증 상태 저장을 위해 이용자의 정보를 수시로 저장하고 불러오는 쿠키(Cookie)를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가
                이용자의 브라우저에 저장하는 텍스트로서 이용자의 컴퓨터 저장장치에 저장됩니다. Ion은 다음 목적을 위해 쿠키를 사용합니다.</p>
              <ul>
                <li>이용자의 인증 상태 저장</li>
              </ul>
              <p>이용자는 쿠키 사용에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저를 통해 쿠키를 허용하거나, 쿠키를 저장할 때 확인을 받거나, 쿠키를 사용하지 않을 수
                있습니다. 다만 쿠키를 사용하지 않을 때 로그인이 필요한 서비스 이용에 어려움이 있을 수 있습니다.</p>
            </li>
            <li>
              <p className='fw-bold'>서비스 이용 통계 분석</p>
              <p>Ion은 서비스 이용 통계 분석을 목적으로 구글(Google)에서 제공하는 로그 분석툴인 Google Analytics를 사용하고 있습니다. Google
                Analytics를 통하여 Ion의 웹사이트 이용자의 행태정보를 수집하게 되며, 이 경우에도 개인을 식별할 수 없는 정보만이 수집됩니다. 이러한 정보처리를
                원하지 않는 경우 아래 안내 페이지를 통해 정보 처리를 거부할 수 있습니다.</p>
              <p>Google Analytics 설정 해제 방법 <Link to='https://tools.google.com/dlpage/gaoptout'
                                                 className='text-decoration-none'
                                                 target='_blank'>보기</Link></p>
            </li>
          </ol>
        </li>
        <li>
          <p className='fw-bold'>개인정보의 기술적, 관리적 보호</p>
          <p>Ion은 개인정보를 다루며 개인정보가 분실, 유출, 변조되지 않도록 다음과 같은 기술적, 관리적 대책을 갖추고 있습니다.</p>
          <ol>
            <li>
              <p className='fw-bold'>개인정보 암호화</p>
              <p>이용자의 개인정보는 암호화된 통신으로 전송되며 비밀번호는 복원 불가능한 방법으로 암호화되어 저장됩니다.</p>
            </li>
            <li>
              <p className='fw-bold'>개인정보처리시스템 접근 제한</p>
              <p>Ion은 개인정보가 저장되고 처리되는 서버에 대한 물리적 접근을 제한하여 개인정보의 유출이 발생하지 않도록 하고 있습니다. 또한 관리에 필수적인 최소한의
                인원만이 개인정보에 접근할 수 있도록 하고 있습니다.</p>
              <p>사용자의 개인정보는 Google이 제공하는 Google Cloud Server를 통하여 저장, 관리됩니다. 이 약관에 동의함에 따라 Google Cloud
                Server를 통한 개인정보의 저장 및 처리에 동의하게 됩니다.</p>
            </li>
            <li>
              <p className='fw-bold'>개인 IonID와 비밀번호 관리</p>
              <p>이용자의 개인정보는 비밀번호에 의해 보호되며 인가되지 않은 접근이 발생하지 않도록 통제되고 있습니다.</p>
              <p>이용자가 사용하는 IonID는 이용자 본인만이 사용하도록 되어있습니다. 이용자의 개인적 부주의로 IonID, 비밀번호, 개인정보가 유출되어 발생하는 문제와
                기본적인 인터넷의 위험성 때문에 일어나는 일들에 대해 Ion은 책임을 지지 않습니다. 충분히 복잡한 비밀번호를 설정하고 공용PC에서 로그인시 개인정보가
                유출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.</p>
            </li>
          </ol>
        </li>
      </ol>
      <h3>이용제한</h3>
      <ol>
        <li>Ion은 이용자가 이 약관을 위반하거나 서비스의 정상적인 운영을 방해한 경우 사전통보 없이 서비스 이용을 제한할 수 있습니다.</li>
        <li>Ion은 이용자가 약관을 심각하게 위반하거나 반복적으로 위반하는 경우 즉시 영구이용정지를 할 수 있습니다.</li>
        <li>Ion의 이용 자격을 갖추지 못한 이용자의 서비스 접근을 제한할 수 있습니다.</li>
      </ol>
      <h3>부정이용 금지 및 차단</h3>
      <ol>
        <li>
          <p>Ion은 다음의 경우를 부정 이용행위로 봅니다.</p>
          <ul>
            <li>동일한 IonID로 2대 이상의 PC 또는 IP에서 서비스를 이용하는 경우</li>
            <li>자신의 IonID를 타인이 이용하도록 하는 경우</li>
            <li>타인의 IonID를 사용하는 경우</li>
            <li>Ion이 제공하는 서비스를 타인에게 판매, 대여, 양도하는 경우</li>
            <li>서비스 이용 중 Ion에 피해를 줄 수 있는 프로그램을 사용하는 경우</li>
          </ul>
        </li>
        <li>Ion은 부정행위를 발견하면 해당 이용자를 서비스 이용 제한할 수 있습니다.</li>
      </ol>
      <h3>면책</h3>
      <ol>
        <li>Ion은 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임에 면제됩니다.</li>
        <li>Ion은 이용자의 귀책사유로 인한 서비스 이용의 장애에 대해서는 책임지지 않습니다.</li>
        <li>Ion은 이용자가 게재한 정보, 자료의 신뢰도, 정확성 등에 관해서는 책임지지 않습니다.</li>
        <li>Ion이 사용하는 외부 API의 장애로 인한 서비스 제공 중단에는 관해서는 책임지지 않습니다.</li>
      </ol>
    </Stack>
  );
}

export default Deula
